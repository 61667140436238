import { FC, useMemo } from 'react';
import { ParagraphLinksFragment } from '../../../generated/types';
import { Box, Flex, Icon, Stack } from '@chakra-ui/react';
import Link from 'next/link';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import { EnhancedLink } from '../../Common/EnhancedLink';

interface LinksProps {
  data: ParagraphLinksFragment;
}

export const Links: FC<LinksProps> = ({ data }) => {
  const justifyContent = useMemo(() => {
    if (data.linksLayout === 'horizontal') {
      if (data.alignment === 'left') {
        return 'flex-start';
      } else if (data.alignment === 'center') {
        return 'center';
      } else if (data.alignment === 'right') {
        return 'flex-end';
      }
    }

    return undefined;
  }, [data]);

  return (
    <Stack
      className={data.__typename}
      spacing={4}
      direction={data.linksLayout === 'vertical' ? 'column' : 'row'}
      justifyContent={justifyContent}
    >
      {data.links?.map((link, i) => {
        return (
          <Box key={i}>
            <EnhancedLink
              href={link.link.url}
              layerStyle={`link_${link.linkLayout}`}
            >
              {link.link.title}

              <Icon ml={1} as={IoChevronForward} />
            </EnhancedLink>
          </Box>
        );
      })}
    </Stack>
  );
};
